<template>
  <div>
    <Title :pages="[{ icon: 'file-invoice', page: 'MARITIMAS | Ordenes Operativas' }]"></Title>

    <v-container fluid>
      <ModalOperaciones
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @print="print"
        @loadList="loadList(service, getFilterParams());"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <v-card>
        <div class="row mb-5 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-md-3 pt-0 ">
                  <v-text-field
                    label="Desde*"
                    data-vv-as="desde"
                    name="desde_selected"
                    v-model="desde_selected"
                    type="date"                    
                    autocomplete="off" 
                    class="pt-0 mt-0"
                  ></v-text-field>
                  <span class="red--text">{{ errors.first("desde_selected") }}</span>                    
                </div>

                <div class="col-md-3 pt-0 ">
                  <v-text-field
                    label="Hasta*"
                    data-vv-as="hasta"
                    name="hasta_selected"
                    v-model="hasta_selected"
                    type="date"                    
                    autocomplete="off" 
                    class="pt-0 mt-0"
                  ></v-text-field>
                  <span class="red--text">{{ errors.first("hasta_selected") }}</span>                  
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox v-model="tipo_fecha_selected" autocomplete="off" data-vv-as="tipos_fechas" name="tipos_fechas" :items="tipos_fechas" label="Fecha*" class="pt-0 mt-0" allow></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    multiple
                    v-model="tipo_contenedores_selected"
                    autocomplete="off"
                    data-vv-as="tipos_contenedores"
                    name="tipos_contenedores"
                    item-text="name"
                    item-value="id"
                    :items="tipos_contenedores"
                    label="Tipo Contenedor"
                    class="pt-0 mt-0"
                    allow
                  ></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-text-field v-model="id_contenedor_selected" autocomplete="off" data-vv-as="id_contenedores" name="id_contenedores" label="IDs de Contenedores" class="pt-0 mt-0"></v-text-field>
                </div>

                <div class="col-md-3 pt-0">
                  <v-text-field v-model="house_bl_selected" autocomplete="off" data-vv-as="house_bl" name="house_bl" label="House BL" class="pt-0 mt-0"></v-text-field>
                </div>
                <div class="col-md-3 pt-0">
                  <v-text-field v-model="master_bl_selected" autocomplete="off" data-vv-as="master_bl" name="master_bl" label="Master BL" class="pt-0 mt-0"></v-text-field>
                </div>
                <div class="col-md-3 pt-0">
                  <v-text-field v-model="factura_selected" autocomplete="off" data-vv-as="factura" name="factura" label="Factura" class="pt-0 mt-0"></v-text-field>
                </div>
                <div class="col-md-3 pt-0">
                  <v-text-field v-model="booking_selected" autocomplete="off" data-vv-as="booking" name="booking" label="Booking" class="pt-0 mt-0"></v-text-field>
                </div>
                <div class="col-md-3 pt-0">
                  <v-text-field
                    v-model="operacion_selected"
                    autocomplete="off"
                    data-vv-as="operacion"
                    name="operacion"
                    label="Nº Operacion"
                    class="pt-0 mt-0"
                  ></v-text-field>
                </div>
                <div class="col-md-3 pt-0">
                  <v-text-field v-model="factura_agente_selected" autocomplete="off" data-vv-as="factura_agente" name="factura_agente" label="Factura Agente" class="pt-0 mt-0"></v-text-field>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox
                    multiple
                    v-model="consignee_selected"
                    autocomplete="off"
                    data-vv-as="consignee"
                    name="consignee"
                    item-text="name"
                    item-value="id"
                    :items="consignees"
                    label="Consignee"
                    class="pt-0 mt-0"
                    allow
                  ></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox                    
                    v-model="buque_selected"
                    autocomplete="off"
                    data-vv-as="buque"
                    name="buque"
                    item-text="name"
                    item-value="id"
                    :items="buques"
                    label="Buque"
                    class="pt-0 mt-0"
                    allow
                  ></v-combobox>
                </div>

                <div class="col-md-3 pt-0">
                  <v-checkbox
                    v-model="alerta_fecha_selected"
                    label="Solo Alertas de Fecha"
                    class="pt-0 mt-0"
                  />                  
                </div>

                <div class="col-md-3 pt-0">
                  
                </div>

                <div class="col-md-3 pt-0" style="display: flex;justify-content: end;">
                  <!--<v-btn color="error" class="mr-2" @click="export_filter">Exportar</v-btn>-->
                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <div v-if="selected.length != 0" class="col-md-12" style="background: #f4f1f1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="green" class="cursor mb-1 mt-1" @click="openModalFechaLlegadaETA">edit</v-icon>
            </template>
            <span>Editar Fecha Llegada ETA</span>
          </v-tooltip>
        </div>
        <v-data-table 
          :headers="headers" :items="list" :search="search" :loading="loading" loading-text="Cargando..." hide-default-footer 
          show-select
          :single-select="false"
          v-model="selected"
          disable-pagination>
          <template v-slot:item="{ item, isSelected, select }">
            <tr :style="getRowStyle(item)">  
              <td>
                <v-checkbox :input-value="isSelected" style="margin: 0px; padding: 0px" hide-details @change="select($event)" :disabled="item.closed" />
              </td>
              <td>{{ item.operacion }}</td>
              <td>
                <v-chip class="mr-1" v-for="obj in item.consignees" :key="obj.id" small> 
                  <span 
                    class="truncate"
                    :title="showName(obj)"
                  >
                    {{ showName(obj) }}
                  </span>                  
                </v-chip>
              </td>
              <td>{{ item.tipos_contenedores }}</td>
              <td>
                <v-chip class="mr-1 mt-1 mb-1" v-for="obj in item.house_bls" :key="obj.id" small> 
                  <span 
                    class="truncate"
                    :title="obj.house_bl"
                  >
                    {{ obj.house_bl }}
                  </span>                  
                </v-chip>
              </td>
              <td>{{ item.booking }}</td>
              <td>{{ item.master_bl }}</td>
              <td>{{ item.fecha_salida_etd }}</td>
              <td>{{ item.fecha_llegada_eta }}</td>
              <td>{{ item.fecha_llegada_ata }}</td>
              <td>{{ showName(item.puerto_salida) }}</td>
              <td>{{ showName(item.buque_actual) }}</td>
              <td>{{ showName(item.puerto_llegada) }}</td>

              <td class="justify-content" style="min-width:230px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="#6e6e6e" class="mr-2" @click="print(item)">
                      print
                    </v-icon>
                  </template>
                  <span>Imprimir Caratula</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access && !item.closed" v-on="on" small color="red" class="mr-2" @click="closeOperacionDialog(item)">
                      close
                    </v-icon>
                  </template>
                  <span>Cerrar Operacion</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="orange" class="mr-2" @click="openHistoryEstados(item)">timer</v-icon>
                  </template>
                  <span>Ver Historial Estados</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="purple" class="mr-2" @click="openHistoryBuques(item, 'Estado')">timer</v-icon>
                  </template>
                  <span>Ver Historial Buques</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="openHistoryAutorizaciones(props.item, 'Autorizaciones')">timer</v-icon>
                  </template>
                  <span>Ver Historial Autorización Facturas Agente</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="blue" class="mr-2" @click="editItem(item)">
                      edit
                    </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-1" @click="viewItem(item)">
                      remove_red_eye
                    </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2" @click="deleteItem(item)">
                      delete
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />
      <DialogLoading :dialog="dialog_print" text="Imprimiendo..." />
      <DialogConfirm :dialog="dialog_close_op" @updateDialog="resetCloseOperacionDialog" @action="closeOperacion" title="Cerrar Orden" :text="close_op_text" />

      <HistoryModal ref="historyStatesModal" />

      <HistoryModalTemplate
        v-if="dialog_historial_autorizaciones"
        :dialog="dialog_historial_autorizaciones"
        title="Autorizaciones"
        :id="item_selected.id"
        :service="service"
        method="history_autorizaciones"
        :item="item_selected"

        :headers="[
          { text: 'Fecha', align: 'left', sortable: true, value: 'fecha_modificacion' },
          { text: 'Factura Agente', value: 'factura_agente.nro_factura' },          
          { text: 'Autorizado', value: 'autorizado' },   
          { text: 'Usuario', value: 'user.username' }
        ]"
        :properties="[
          'fecha_modificacion',
          'nro_factura',
          'autorizado',
          'username'
        ]"
        @close="closeHistoryAutorizaciones"
      />

      <ModalFechaLlegadaETA
        :service="service"
        :dialog="modal_fecha_llegada_eta"
        :items="selected"
        @updateDialog="closeModalFechaLlegadaETA"
        @loadList="filter"
      />
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/";
import { generalService } from "@/libs/ws/generalService";
import { ordersGeneralService } from "@/libs/ws/ordersGeneralService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import DialogConfirm from "@/components/DialogConfirm.vue";
import DialogLoading from "@/components/DialogLoading.vue";
import ModalOperaciones from "./ModalOperaciones.vue";
import ModalFechaLlegadaETA from "@/components/ModalFechaLlegadaETA.vue";
import HistoryModal from "./HistoryModal.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import HistoryModalTemplate from "@/components/HistoryModalTemplate.vue";

export default {
  components: { Title, HistoryModal, ModalOperaciones, DialogDelete, DialogLoading, DialogConfirm, ModalFechaLlegadaETA, HistoryModalTemplate },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      selected: [],
      parent: this,
      service: ordersGeneralService,
      page_title: "Ordenes Maritimas",
      modal_title: "Orden Maritima",
      dialog_close_op: false,
      modal_fecha_llegada_eta: false,
      close_op_text: "",      
      mainItem: {
        id: "",
        lcl: false,
        bultos: null,
        kilos: null,
        m3: null,
        en_transito: null,
        observaciones_housebl: null,

        shippers: null,
        consignees: null,

        operacion: null,

        cantidad_contenedores: null,
        contenedores: [],
        facturas: [],
        facturas_agentes: [],
        packing_lists: [],
        documentos: [],
        historial_buques: [],
        documentaciones_entrega: [],

        referencia_cliente: null,
        house_bls: [],
        buque_salida: null,
        booking: null,
        master_bl: null,
        fecha_recoleccion: null,
        fecha_recoleccion_confirmada: null,
        fecha_salida_etd: null,
        fecha_salida_atd: null,
        fecha_llegada_eta: null,
        fecha_llegada_ata: null,
        estado: null,
        comentarios: null,
        puerto_salida: null,        
        puerto_llegada: null,

        naviera: null,
        manis: [],
        fecha_descarga_trasbordo: null,
        fecha_carga_trasbordo: null,
        sim: null,
        fecha_carga_mani: null,
        pago_mani: null,
        proformas_control: null,
        proformas_numero: null,
        courier_path: null,
        courier_attached: null,
        courier_numero: null,
        courier_control: null,
        fecha_recepcion_courier: null,
        fecha_envio_doc_oficina_centro: null,
        terminal_dep_fiscal: null,
        observaciones: null,
        viaje_arribo_puerto: null,
        codigo_puerto: null,
        fecha_transmision_anticipada: null,
        agente: null,
      },
      defaultItem: null,
      headers: [
        { text: "Nº Operacion", align: "left", sortable: true, value: "operacion" },
        { text: "Consignees", align: "left", sortable: true, value: "consignees.name" },
        { text: "Tipos de contendores", align: "left", sortable: true, value: "tipos_contenedores" },
        { text: "House BLs", align: "left", sortable: true },
        { text: "Booking", align: "left", sortable: true, value: "booking" },
        { text: "Master BL", align: "left", sortable: true, value: "master_bl" },
        { text: "Fecha Salida ETD", align: "left", sortable: true, value: "fecha_salida_etd" },
        { text: "Fecha Llegada ETA", align: "left", sortable: true, value: "fecha_llegada_eta" },
        { text: "Fecha Llegada ATA", align: "left", sortable: true, value: "fecha_llegada_ata" },
        { text: "Puerto de salida", align: "left", sortable: true, value: "puerto_salida.name" },
        { text: "Buque actual", align: "left", sortable: true, value: "buque_actual.name" },
        { text: "Puerto de llegada", align: "left", sortable: true, value: "puerto_llegada.name" },
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
      //Dialogs
      dialog_delete: false,
      dialog_print: false,
      //Combos
      tipos_contenedores: [],
      shippers: [],
      consignees: [],
      buques: [],
      //Filters
      tipos_fechas: ["FECHA CREACION","FECHA SALIDA ( ETD )", "FECHA SALIDA ( ATD )", "FECHA LLEGADA ( ETA )", "FECHA LLEGADA ( ATA )"],
      tipo_fecha_selected: "FECHA CREACION",
      alerta_fecha_selected: null,
      factura_selected: null,
      factura_agente_selected: null,
      operacion_selected: null,
      tipo_contenedores_selected: null,
      consignee_selected: null,
      id_contenedor_selected: null,
      house_bl_selected: null,
      master_bl_selected: null,
      buque_selected: null,
      booking_selected: null,
      desde_selected_open: false,
      hasta_selected_open: false,
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),

      dialog_historial_autorizaciones: false,
      item_selected: null,
    };
  },
  mounted() {
    this.service.setSection(Constant.SECTION_ORDERS_MARITIMAS_OP);
    this.loadComboData();
    this.defaultItem = Object.assign({}, this.mainItem);
    this.loadList(this.service, this.getFilterParams());
    this.tipo_fecha_selected = this.tipos_fechas[0];
  },
  methods: {
    showName(element) {
      return element != null ? element.name : "-";
    },
    openHistoryEstados(order) {
      this.$refs.historyStatesModal.open(
        "Estados",
        [
          { text: "Fecha", align: "left", sortable: true, value: "fecha_modificacion" },
          { text: "Estado", value: "estado.name" },
        ],
        order.historial_estados,
        "fecha_modificacion",
        "estado"
      );
    },
    openHistoryBuques(order) {
      this.$refs.historyStatesModal.open(
        "Buques",
        [
          { text: "Fecha", align: "left", sortable: true, value: "fecha_modificacion" },
          { text: "Buque", value: "buque.name" },
        ],
        order.historial_buques,
        "fecha_modificacion",
        "buque"
      );
    },
    openHistoryAutorizaciones(order){
      this.item_selected = order;
      this.dialog_historial_autorizaciones = true;
    },
    closeHistoryAutorizaciones(){
      this.dialog_historial_autorizaciones = false;
      this.item_selected = null;
    },
    getFilterParams() {
      const param = {
        desde: this.desde_selected,
        hasta: this.hasta_selected,
        tipo_fecha: this.tipo_fecha_selected,

        buque: (this.buque_selected != null)?this.buque_selected.id:null,

        house_bl: this.house_bl_selected,
        master_bl: this.master_bl_selected,
        booking:  this.booking_selected,
        factura: this.factura_selected,
        operacion: this.operacion_selected,
        factura_agente: this.factura_agente_selected,
        consignee: this.consignee_selected,

        id_contenedor: this.id_contenedor_selected,
        tipo_contenedores: this.tipo_contenedores_selected,

        alerta_fecha: (this.alerta_fecha_selected)?this.alerta_fecha_selected:null,
      };
      return param;
    },
    filter() {
      /*
      if(
        this.desde_selected == null ||
        this.hasta_selected == null ||
        this.tipo_fecha_selected == null
      ){
        this.$toast.error("Debe seleccionar una fecha para filtrar.","Error");
        return;
      }
      */
      this.loadList(this.service, this.getFilterParams());
    },
    loadComboData() {
      generalService.filter({}, "shippers").then((response) => {
        this.shippers = response.result;
      });
      generalService.filter({}, "consignees").then((response) => {
        this.consignees = response.result;
      });
      generalService.filter({}, "tipos_contenedores").then((response) => {
        this.tipos_contenedores = response.result;
      });
      generalService.filter({}, "buques").then((response) => {
        this.buques = response.result;
      });
    },
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadList(this.service, this.getFilterParams());
    },
    updateDialog(value, next_flag = false) {
      this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
      if (!value) {
        this.closeModal(next_flag);
      } else {
        this.dialog = value;
      }
    },
    closeModal(next_flag) {
      if (!next_flag) {
        this.dialog = false;
        this.close();
        return;
      }

      const mainItem_aux = this.mainItem;

      this.mainItem = Object.assign({}, this.defaultItem);

      this.mainItem.shippers = mainItem_aux.shippers;
      this.mainItem.consignees = mainItem_aux.consignees;
      this.mainItem.inssuing_carrier = mainItem_aux.inssuing_carrier;
      this.mainItem.departure_airport = mainItem_aux.departure_airport;
      this.mainItem.arrival_airport = mainItem_aux.arrival_airport;
      this.mainItem.carrier = mainItem_aux.carrier;
      this.mainItem.currency = mainItem_aux.currency;
      this.mainItem.transshipment_airport = mainItem_aux.transshipment_airport;

      this.dialog = false;
      this.editedIndex = -1;
      this.viewdIndex = -1;
      this.errors.clear();
    },
    export_filter() {
      this.service.export_filter(this.getFilterParams()).then((response) => {
        try {
          //Transformo a string la respuesta para poder enviar mensajes de error.
          let result = JSON.parse(this.arrayBufferToString(response));
          if (!result.status) {
            this.$toast.error(result.msg, "Error");
          }
        } catch (e) {
          let blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          let link = document.createElement("a");
          //link.setAttribute('download', "export")
          link.download = "reporte.xlsx";
          link.href = window.URL.createObjectURL(blob);
          link.target = "_blank";
          //link.download = code+'.pdf'
          link.click();
        }
      });
    },        
    closeOperacionDialog(item) {
      this.mainItem = item;
      this.close_op_text = "¿Está seguro que desea cerrar la operación " + item.operacion + "?";
      this.dialog_close_op = true;
    },
    resetCloseOperacionDialog() {
      this.dialog_close_op = false;
      this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
    },
    closeOperacion(){
      this.loading = true;
      return this.service
        .closeOperacion(this.mainItem.id)
        .then((response) => {
          if (response.status == true) {
            this.$toast.success("La orden " + this.mainItem.operacion + " se cerró correctamente.", "OK");
            this.filter();
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.$toast.error(response.message, "Error");
            } else {
              this.$toast.error(response.msg, "Error");
            }
          }
        })
        .catch((response) => {
          this.$toast.error(response.msg, "Error");
        })
        .finally(() => {
          this.resetCloseOperacionDialog();
          this.loading = false;
        });
    },  
    openModalFechaLlegadaETA() {
      if (this.selected.length == 0) {
        this.$toast.error("Debe seleccionar al menos una orden.", "Error");
        return;
      }
      this.modal_fecha_llegada_eta = true;
    },
    closeModalFechaLlegadaETA() {
      this.modal_fecha_llegada_eta = false;
    },
    print(orden) {
      this.dialog_print = true;
      this.service.print(orden.id).then((response) => {
        try {
          //Transformo a string la respuesta para poder enviar mensajes de error.
          let result = JSON.parse(this.arrayBufferToString(response));
          if (!result.status) {
            this.$toast.error(result.msg, "Error");
          }
          this.dialog_print = false;
        } catch (e) {
          this.dialog_print = false;
          let blob = new Blob([response], {
            type: "application/pdf",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = orden.guia_madre + ".pdf";
          link.target = "_blank";
          link.click();
        }
      });
    },  
    getRowStyle(item) {
      let style = "";
      if (item.alerta_fecha != null && item.alerta_fecha) {
        style += "background-color: #ffa571;";
      }
      
      if(item.closed){
        style += "background-color: #cdcdcd;";
      }     

      return style;   
    },
  },
};

// TODO agregar un boton de limpiar busqueda.
</script>
